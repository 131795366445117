<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">NEW MEMBER</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form2" class="multi-col-validation">
        <v-flex xs12 md4>
          <v-text-field
            class="mx-2 mt-5"
            v-model="date_registered"
            label="Date Registered"
            dense
            outlined
            type="date"
            :rules="rules.default_max_45_character_and_no_empty_rule"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md4>
          <v-select
            class="mx-2"
            v-model="type_of_member"
            dense
            outlined
            label="Type Of Member"
            :items="['Individual','Business']"
            :rules="rules.combobox_rule"
          ></v-select>
        </v-flex>
        <v-layout row wrap class="align-center mx-2 mt-3">
          <v-flex xs12 md12 v-if="type_of_member==='Business'">
            <v-text-field
              class="mx-2"
              v-model="company_name"
              label="Company Name"
              dense
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4 v-if="type_of_member==='Individual'">
            <v-text-field
              class="mx-2"
              v-model="last_name"
              label="Last Name"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4 v-if="type_of_member==='Individual'">
            <v-text-field
              class="mx-2"
              v-model="first_name"
              label="First Name"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4 v-if="type_of_member==='Individual'">
            <v-text-field
              class="mx-2"
              v-model="middle_name"
              label="Middle Name"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-textarea
              class="mx-2"
              v-model="address"
              dense
              label="Address"
              outlined
              :rules="rules.default_no_empty_rule"
            ></v-textarea>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              class="mx-2"
              v-model="email_address"
              label="Email Address"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4>
            <v-select
              class="mx-2"
              v-model="type_of_business"
              dense
              outlined
              label="Type Of Business"
              :items="type_of_member==='Individual'?['Individual']:['Sole Proprietorship','Partnership or Corporation']"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              class="mx-2"
              v-model="contact_no"
              label="Contact #"
              dense
              outlined
              type="number"
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              class="mx-2"
              v-model="annual_gross_income"
              label="Annual Gross Income"
              dense
              outlined
              type="number"
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12 class="mt-4" v-if="type_of_member==='Business'">
            <v-card
              flat
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">OWNER DATA</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-row class="mt-3">
                  <v-col
                    md="12"
                    cols="12"
                    v-if="!for_update"
                  >
                    <v-card-actions class="px-2">
                      Add More Business Owner
                      <v-btn class="mx-2" fab dark small color="success"
                             @click="add_business_owner">
                        <v-icon dark>{{icons.mdiPlus}}</v-icon>
                      </v-btn>
                      <v-divider class="mx-2"/>
                    </v-card-actions>
                  </v-col>
                  <v-col
                    md="12"
                    cols="12"
                  >
                    <v-col v-for="(item, index) in business_owner_items" :key="index">
                      <v-card-actions v-if="!for_update">
                        <v-spacer/>
                        Remove Business Owner
                        <v-btn class="mx-2" fab dark small color="error"
                               @click="remove_business_owner(index)">
                          <v-icon dark>{{icons.mdiClose}}</v-icon>
                        </v-btn>
                      </v-card-actions>
                      <v-row class="align-center mb-2">
                        <v-col md="2"
                               cols="12">
                          <v-text-field
                            v-model="item.position"
                            label="Position"
                            class="mx-2"
                            :rules="rules.default_max_45_character_and_no_empty_rule"
                            required
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col md="2"
                               cols="12">
                          <v-text-field
                            v-model="item.last_name"
                            label="Owner Last Name"
                            class="mx-2"
                            :rules="rules.default_max_45_character_and_no_empty_rule"
                            required
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col md="2"
                               cols="12">
                          <v-text-field
                            v-model="item.first_name"
                            label="Owner First Name"
                            class="mx-2"
                            :rules="rules.default_max_45_character_and_no_empty_rule"
                            required
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col md="2"
                               cols="12">
                          <v-text-field
                            v-model="item.middle_name"
                            label="Owner Middle Name"
                            class="mx-2"
                            :rules="rules.default_max_45_character_and_no_empty_rule"
                            required
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col md="2"
                               cols="12">
                          <v-text-field
                            v-model="item.email_address"
                            label="Owner Email Address"
                            class="mx-2"
                            :rules="rules.default_max_45_character_and_no_empty_rule"
                            required
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col md="2"
                               cols="12">
                          <v-text-field
                            v-model="item.contact_no"
                            label="Owner Contact #"
                            class="mx-2"
                            :rules="rules.default_max_45_character_and_no_empty_rule"
                            required
                            dense
                            type="number"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-divider class="mx-2"/>
                    </v-col>
                  </v-col>

                </v-row>

              </v-card-text>
            </v-card>
          </v-flex>
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              color="primary"
              @click="save_request"
              v-if="!saving_data"
            >
              Save Changes
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-layout>
      </v-form>
    </v-card>
    <list-of-member v-if="!for_update" :key="this.key" :is_active="1"/>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiCheck, mdiClose
    , mdiAccountOff
    , mdiAccountCheck
    , mdiPlus
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import ListOfMember from '@/views/register_interface/member_component/ListOfMember'

  const initialState = () => {
    return {
      key: 0,

      saving_data: false,
      alert: false,
      alert_message: '',

      business_owner_items: [],

      date_registered: '',
      last_name: '',
      first_name: '',
      middle_name: '',
      company_name: '',
      address: '',
      email_address: '',
      type_of_member: '',
      type_of_business: '',
      contact_no: '',
      annual_gross_income: '',
    }
  }
  export default {
    props: {
      for_update: Boolean,
      information_details: Object,
    },
    components: {
      snackBarDialog,
      ListOfMember,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiAccountOff
          , mdiAccountCheck
          , mdiPlus
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      if (this.for_update) {
        this.date_registered = this.information_details.date_registered
        this.last_name = this.information_details.last_name === null ? '' : this.information_details.last_name
        this.first_name = this.information_details.first_name === null ? '' : this.information_details.first_name
        this.middle_name = this.information_details.middle_name === null ? '' : this.information_details.middle_name
        this.company_name = this.information_details.company_name
        this.address = this.information_details.address
        this.email_address = this.information_details.email_address
        this.type_of_member = this.information_details.is_business ? 'Business' : 'Individual'
        this.type_of_business = this.information_details.type_of_business
        this.contact_no = this.information_details.contact_no + ''
        this.annual_gross_income = this.information_details.annual_gross_income + ''
        this.is_business = this.information_details.is_business
        this.is_individual = this.information_details.is_individual

        const data = new FormData()
        data.append('member_id', this.information_details.id);
        this.get_list_of_business_owner(data)
          .then(response => {
            this.business_owner_items = response.data
          })
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('members_information', ['create_member',]),
      ...mapActions('business_owners', ['get_list_of_business_owner']),
      add_business_owner() {
        this.business_owner_items.push(
          {
            position: '',
            last_name: '',
            first_name: '',
            middle_name: '',
            email_address: '',
            contact_no: '',
          }
        )
      },
      remove_business_owner(index) {
        this.business_owner_items.splice(index, index + 1)
      },
      save_request() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form2.validate()) {
          const str = this.contact_no;
          const firstTwoChars = str.slice(0, 2);
          if (firstTwoChars != '09') {
            this.alert = true
            this.alert_message = 'CONTACT # MUST START 09'
            this.saving_data = false
          } else {
            const data = new FormData()
            data.append('date_registered', this.date_registered);
            data.append('company_name', this.type_of_member === 'Business' ? this.company_name.toUpperCase() : this.last_name.toUpperCase() + ', ' + this.first_name.toUpperCase() + ' ' + this.middle_name.toUpperCase());
            data.append('last_name', this.last_name.toUpperCase());
            data.append('first_name', this.first_name.toUpperCase());
            data.append('middle_name', this.middle_name.toUpperCase());
            data.append('address', this.address.toUpperCase());
            data.append('email_address', this.email_address);
            data.append('type_of_business', this.type_of_business);
            data.append('contact_no', this.contact_no);
            data.append('annual_gross_income', this.annual_gross_income);
            data.append('is_business', this.type_of_member === 'Business' ? 1 : 0);
            data.append('is_individual', this.type_of_member === 'Individual' ? 1 : 0);
            data.append('business_owner', JSON.stringify(this.business_owner_items));
            this.create_member(data)
              .then(response => {
                var color = 'error'
                if (response.status === 200) {
                  color = 'success'
                  Object.assign(this.$data, initialState())
                  this.key++
                }
                this.change_snackbar({
                  show: true,
                  color: color,
                  text: response.data,
                })
                this.saving_data = false
              })
              .catch(error => {
                console.log(error)
              })
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
    },
  }
</script>
